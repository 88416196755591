<template>
    <div class="tiptap-editor-menu" v-if="editor">
        <!-- <div @click="showHTML()" :class="{ 'is-active': showHTMLEditor }">HTML</div> -->
        <div @click="showHTMLEditor = false; editor.chain().focus().unsetLink().run()" v-if="editor.isActive('link')">Remove Link</div>
        <div @click="showHTMLEditor = false; showLink()" :class="{ 'is-active': showLinkInput }">Link</div>
        <div @click="showHTMLEditor = false; editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">Italic</div>
        <div @click="showHTMLEditor = false; editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">Bold</div>
        <input type="text" :class="['link-input', { 'shown': showLinkInput }]" ref="linkinput" v-model="linkValue" placeholder="Paste URL..." @keyup.enter="setLink()">
    </div>
    <div class="editor-wrapper">
        <editor-content :editor="editor" />
        <textarea :class="['tiptap-html-content', { 'html-content-active': showHTMLEditor }]" v-model="htmlContent"></textarea>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'

export default {
    components: {
        EditorContent
    },
    props: {
        modelValue: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            editor: null,
            showLinkInput: false,
            linkValue: '',
            showHTMLEditor: false,
            htmlContent: ''
        }
    },
    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value
            if (isSame) { return }
            this.editor.commands.setContent(value, false)
        }
    },
    mounted() {
        this.editor = new Editor({
            content: this.modelValue,
            extensions: [
                StarterKit,
                Link.configure({
                    openOnClick: false
                })
            ],
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.getHTML())
            },
            editorProps: {
                attributes: {
                    class: 'tiptap-editor-content',
                }
            }
        })
    },
    methods: {
        showLink() {
            if (this.editor.isActive('link')) { return }
            if (this.editor.view.state.selection.empty) { return }
            this.showLinkInput = !this.showLinkInput
            this.$refs.linkinput.focus()
        },
        setLink() {
            this.showLinkInput = false
            if (this.linkValue) {
                this.editor.commands.setLink({ href: this.linkValue })
                this.this.linkValue = ''
            }
        },
        showHTML() {
            if (this.showHTMLEditor) {
                this.editor.commands.setContent(this.htmlContent)
                this.$emit('update:modelValue', this.editor.getHTML())
            } else {
                this.htmlContent = this.editor.getHTML()
            }
            this.showHTMLEditor = !this.showHTMLEditor
        }
    },
    beforeUnmount() {
        this.editor.destroy()
    }
}
</script>
