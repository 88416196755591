<template>
	<div>

	<div class="section-heading">{{ localCaptializeWords(section) }} Image</div>
	<div v-if="!editing" class="image-picker-list">
		<div class="image-wrapper">
			<img v-if="imageURL" :src="imageURL" />
			<!-- <div v-else class="empty-content">No Image</div> -->
		</div>
		<div>
        	<div :class="['button', Number.isInteger(primary_id) ? 'button-dark' : 'disabled']" @click="editing = true"><font-awesome-icon icon="edit" /></div>
        	<div v-if="imageId" class="button button-dark" @click="deleteImage()"><font-awesome-icon icon="trash" /></div>
		</div>
	</div>

	<div v-if="editing">
		<label>Upload a new image</label>
        <Dropzone fileType="image" :imageId="image_id || 0" @update="setDropzoneImage" />
		<label>New image's description</label>
		<input type="text" v-model="imageDescription" />

        <div class="detail-image-picker-detail-controls">
            <div class="button cancel" @click="cancelEditing()">Cancel</div>
            <div class="button" @click="saveImage()">Save Content Image</div>
            <Message :message="message" :error="errorStatus" delay="3" :key="message" />
        </div>

		<div class="divider"></div>
		<label style="margin: 0.5em 0 0 0;">Search for a library image</label>
        <div class="search-controls">
            <input type="search" v-model="search_term" placeholder="Search..." @search="doSearch()">
            <div class="search-control-button" @click="doSearch()"><font-awesome-icon icon="search" class="search-icon"/></div>
        </div>
		<div class="detail-image-search-list">
			<div v-for="(item, index) in searchList" class="detail-image-search-item">
				<img :src="item.image_url" />
				<div class="detail-image-search-meta">
					{{ item.description }}
				</div>
			</div>
		</div>
	</div>

    <Message :message="message" :error="errorStatus" delay="3" :key="message" />

</div>

</template>
<script>
import Message from '@/components/Message.vue'
import Dropzone from '@/components/Dropzone.vue'
import { mapActions } from 'vuex'
export default {
	name: 'detail-list-picker',
    props: {
		section: {
            type: String,
            default: ''
		},
        primary_id: {
            type: [Number, Boolean],
            default: false
        },
        image_id: {
            type: [Number, Boolean],
            default: false
        },
        image_url: {
            type: String,
            default: ''
        },
        disabled: {
            type: [String, Boolean],
            default: false
        }
    },
    components: {
        Message,
		Dropzone
    },
	data() {
		return {
			imageId: '',
			imageURL: '',
			imageDescription: '',
			search_term: '',
			searching: false,
			searchList: [],
			editing: false,
            message: '',
            errorStatus: false
		}
	},
    created() {
		this.imageId = this.image_id
		this.imageURL = this.image_url
		if (this.image_id) {
        	//this.getList()
		}
	},
    computed: {
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        localCaptializeWords(words) {
            return this.caplitalizeWords(words)
        },
        doSearch() {
			if (this.searching || !this.search_term) { return }
    		this.apiCall({ destination: 'get_list_images', data: { search_term: this.search_term } }).then(obj => {
				this.searching = false
    			if (obj.status == 'success') {
		            this.searchList = obj.list
					console.log(this.searchList)
    			} else {
					this.message = obj.message
				}
    		})
        },
		filterList(list) {
			return this.list.filter(item => item.list == list)
		},
        setDropzoneImage(image) {
			// this.imageId = image.image_id
			// this.imageURL = image.image_url
			// emitting update will refresh this component, so no need to reset local properties here
            this.$emit('update', image)
		},
		deleteImage() {

		},
		cancelEditing() {
			this.editing = false
		}
	}
}
</script>
<style lang="scss" scoped>
.image-picker-list {
    display: grid;
    grid-template-columns: 1fr 2.5em;
    grid-column-gap: 2em;
    margin: 1em 0 0 0;
	.image-wrapper {
		width: 100%;
		//height: 9em;
		img { max-height: 100%; }
	}
    input { font-size: 0.9em; }
    .button {
		font-size: 0.7em;
		height: 2.75em;
		width: 100%;
		margin: 0 0 0.5em 0;
	}
}
.detail-image-search-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 0.5em;
	.detail-image-search-item {
		border: 1px solid #ffffff;
		img { max-width: 100%; }
		.detail-image-search-meta {
			font-size: 0.8em;
			line-height: 1em;
			padding: 0.25em;
		}
	}
	.detail-image-search-item:hover {
		border: 1px solid #ccc;
		cursor: pointer;
	}
}
.detail-image-picker-detail-controls {
	margin: 0 0 0.75em 0;
    .button { margin: 0 1em 0 0;}
}
</style>
