<template>
	<label>
		<span v-if="!condition" class="icon" @mouseover="required = label + ' is Required'" @mouseleave="required = ''">*</span>
		<span v-if="label && !required">{{ label }}</span>
		<span v-if="required" class="required">{{ required }}</span>
	</label>
</template>
<script>
export default {
	name: 'required-field-label',
	props: ['label','condition'],
	data () {
		return {
			required: ''
		}
	}
}
</script>
<style lang="scss" scoped>
.icon {
	font-size: 1em;
	width: 1em; height: 1em;
	padding: 0 0.25em 0 0;
	color: #fc4a1a;
	cursor: pointer;
}
.required {
	color: #fc4a1a;
}
</style>
