<template>
<div class="main-content pages">
    <h1>Content<span v-if="contentTypeCode"> <font-awesome-icon icon="chevron-right" /> {{ localCaptializeWords(contentTypeCode) }}s</span></h1>
    <div class="filtered-list-subhead">
        <font-awesome-icon class="mobile-filters-button" :icon="showFilters ? 'times-circle' : 'plus-circle'" @click="showFilters = !showFilters" />
        <div class="search-controls">
            <input type="search" v-model="filters.search_term" placeholder="Search..." @search="doSearch()">
            <div class="search-control-button" @click="doSearch()"><font-awesome-icon icon="search" class="search-icon"/></div>
        </div>
        <div></div>
    	<div class="page-controls">
    		<Pagination :paging="paging" @updatePage="goToPage" />
    	</div>
    </div>
    <div class="filtered-list">
        <div v-if="showFilters" class="filtered-list-sidebar">
            <div v-if="contentId" class="filtered-list-sidebar-controls">
                <label>Actions...</label>
        		<div class="button save" @click="saveItem()"><LabelWithLoadingIcon :loading="saving" label="Save Content" /></div>
                <div class="button button-dark" @click="backToList()">Back To List</div>
            </div>
            <div v-else class="filtered-list-sidebar-controls">
                <label>Filters...</label>
                <select v-model="filters.content_type_id" @change="getList(1)">
                    <option value="">Content Type...</option>
                    <option v-for="(contentType, index) in contentTypes" :value="contentType.content_type_id">{{ contentType.description }}</option>
                </select>
                <select v-model="filters.author_id" @change="getList(1)">
                    <option value="">Author...</option>
                    <option v-for="(contentAuthor, index) in contentAuthors" :value="contentAuthor.author_id">{{ contentAuthor.name }}</option>
                </select>
                <hr>
                <label>Actions...</label>
                <div class="button button-dark" @click="newItem()">Create Content</div>
                <div :class="['button', list.length > 0 ? 'button-dark' : 'disabled']" @click="doExport()">Export List</div>
                <div class="note">{{ filterMessage }}</div>
            </div>
        </div>
        <div v-if="!contentId" class="filtered-list-content">
            <table class="filtered-list-table">
                <tr class="clickable-list-header">
                    <th>Description</th>
                    <th>Excerpt</th>
                    <th>Created</th>
                    <th>By</th>
                    <th>Published</th>
                </tr>
                <tr v-for="(item, index) in list" class="clickable-list" @click="getItemRoute(item.content_id)">
                    <td>{{ item.description }}</td>
                    <td>{{ item.excerpt }}</td>
                    <td>{{ item.created_date }}</td>
                    <td>{{ item.user_name }}</td>
                    <td><font-awesome-icon v-if="item.published" icon="check"/></td>
                </tr>
            </table>
        </div>
        <div v-else class="filtered-list-item">
            <div class="filtered-list-item-two-columns">
                <div>
                    <div class="section-heading">Content Details</div>
                    <div class="two-columns">
                        <div>
                            <LabelRequired label="Content Type" :condition="detail.content_type_id"/>
                            <select v-model="detail.content_type_id">
                                <option value="">Select...</option>
                                <option v-for="(contentType, index) in contentTypes" :value="contentType.content_type_id">{{ contentType.description }}</option>
                            </select>
                        </div>
                        <div>
                            <label>Hide</label>
                            <div class="checkbox" @click="detail.internal_use_only = !detail.internal_use_only"><font-awesome-icon v-if="detail.internal_use_only" icon="check"/></div>
                        </div>
                    </div>
                    <LabelRequired label="Description" :condition="detail.description"/>
                    <input type="text" v-model="detail.description">
                    <label>Excerpt</label>
                    <textarea v-model="detail.excerpt"></textarea>
                    <label>Main Content
                        <span class="button button-dark button-tiny" style="float: right; width: 12em; margin: -0.2em -1em 0 0; padding: 0.7em 1em;" @click="useTipTap = !useTipTap">{{ useTipTap ? 'Switch To HTML' : 'Use Editor' }}</span>
                    </label>
                    <TiptapEditor v-if="useTipTap" v-model="detail.main_content" />
                    <textarea v-else v-model="detail.main_content" style="height: 17em; margin: 0 0 0.25em 0;"/>
                    <label>URL Name</label>
                    <input type="text" v-model="detail.link_name">
                </div>
                <div>
                    <div class="section-heading">Content Publishing</div>
                    <div class="two-columns spaced">
                        <label>Author</label>
                        <label>Code</label>
                        <select v-model="detail.author_id">
                            <option value="">Select...</option>
                            <option v-for="(contentAuthor, index) in contentAuthors" :value="contentAuthor.author_id">{{ contentAuthor.name }}</option>
                        </select>
                        <input type="text" v-model="detail.content_code"/>
                        <label>Publish Date</label>
                        <label>Expire Date</label>
                        <input type="date" v-model="detail.published_date" />
                        <input type="date" v-model="detail.expired_date" />
                        <label>Publish Time</label>
                        <label>Expire Time</label>
                        <input type="time" v-model="detail.published_time" />
                        <input type="time" v-model="detail.expired_time" />
                    </div>
                    <DetailImagePicker section="content" :primary_id="detail.content_id" :image_id="detail.image_id" :image_url="detail.image_url" @update="setImage" :key="'image_' + detail.image_id" />
                    <DetailListPicker section="content" list_name="spheres" :primary_id="detail.content_id" :key="'spheres_' + detail.content_id" />
                    <DetailListPicker section="content" list_name="tags" :primary_id="detail.content_id" :key="'tags_' + detail.content_id" />
                </div>
            </div>

        </div>
    </div>

    <MessageModal v-if="message" :message="message" :loading="loading" :errorStatus="errorStatus" @dismiss="message = ''" />

    <iframe id="download" src="" style="display: none;"></iframe>

</div>
</template>
<script>
import Pagination from '@/components/Pagination.vue'
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import LabelRequired from '@/components/LabelRequired.vue'
import MessageModal from '@/components/MessageModal.vue'
import TiptapEditor from '@/components/TiptapEditor.vue'
import DetailListPicker from '@/components/DetailListPicker.vue'
import DetailImagePicker from '@/components/DetailImagePicker.vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
    name: 'Login',
    components: {
        Pagination,
        LabelWithLoadingIcon,
        LabelRequired,
        MessageModal,
        TiptapEditor,
        DetailListPicker,
        DetailImagePicker
    },
    data() {
        return {
            loading: false,
            message: '',
            errorStatus: '',
            filterMessage: '',
            contentTypes: [],
            contentAuthors: [],
            list: [],
            contentTypeCode: '',
            contentId: '',
            detail: {},
            paging: {},
            filters: {
                page: 1,
                search_term: '',
                content_type_id: '',
                author_id: ''
            },
            saving: false,
            saved: false,
            showFilters: false,
            useTipTap: true
        }
    },
    created() {
        this.getContentTypes()
        this.getAuthors()
        this.contentId = this.$route.params.content_id
        if (this.contentId) {
            this.getItem(this.contentId)
        } else {
            this.getList()
        }
        this.showFilters = window.matchMedia("(min-width: 768px)").matches
    },
    watch: {
        '$route.params.content_id': function (contentId) {
            if (contentId) {
                this.contentId = contentId
                this.getItem(contentId)
            } else {
                this.detail = {}
                this.contentId = ''
                this.saved = false
                this.getList()
            }
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getUser',
            'getDownloadEndpoint'
        ]),
        hasFilters() {
            return this.filters.page > 1 || this.filters.search_term
        }
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        getContentTypes() {
		    this.message = ''
            this.errorStatus = false
    		this.apiCall({ destination: 'get_list_content_types' }).then(obj => {
                this.message = obj.message
    			if (obj.status == 'success') {
                    this.contentTypes = obj.content_types
                }
            })
        },
        getAuthors() {
		    this.message = ''
            this.errorStatus = false
    		this.apiCall({ destination: 'get_list_content_authors' }).then(obj => {
                this.message = obj.message
    			if (obj.status == 'success') {
                    this.contentAuthors = obj.content_authors
                }
            })
        },
        localCaptializeWords(words) {
            // localize functions in main.js allows use in template
            return this.caplitalizeWords(words)
        },
        getList(page) {
            if (this.loading) { return }
            if (page) {
                this.filters.page = page
            }
		    this.message = this.filters.do_export ? 'Getting the export list...' : ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_list_content', data: this.filters }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    if (this.filters.do_export) {
        				// set hidden iframe src to the API's download URL
        				document.getElementById("download").src = this.getDownloadEndpoint + "?file_name=" + obj.file_name + "&output_file_name=" + obj.file_name
        				this.message = obj.total_items + ' items exported'
        				// var timer = setTimeout((function() {
        				// 	this.filterMessage = ''
        				// }.bind(this)),3000)
                        this.filters.do_export = false
                    } else {
                        this.detail = {}
    					this.list = obj.list
                        this.paging = obj.paging
                        window.scrollTo(0, 0);
                    }
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        doExport() {
			this.message = 'Generating export file...'
            this.filters.do_export = true
            this.getList()
        },
        setFilter(which) {
            this.filters[which] = !this.filters[which]
            this.getList()
        },
        clearFilters() {
            this.filters = {
                page: 1,
                search_term: ''
            }
            this.getList()
        },
        doSearch() {
            this.getList()
        },
		goToPage(page) {
			this.filters.page = page
			this.getList()
		},
        getItemRoute(contentId) {
            this.$router.push('/content/detail/' + contentId)
        },
        getItem(contentId) {
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = false
            if (contentId == 'new') {
    			this.detail = {
                    content_id: '',
                    content_type_id: '',
                    image_url: ''
    			}
                if (this.$route.query.content_type_id) {
                    this.detail.content_type_id = parseInt(this.$route.query.content_type_id)
                }
                return
            }
			this.loading = true
    		this.apiCall({ destination: 'get_item_content', data: { content_id: contentId } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.detail = obj.detail
                    window.scrollTo(0, 0);
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        newItem() {
            this.$router.push('/content/detail/new' + (this.filters.content_type_id ? '?content_type_id=' + this.filters.content_type_id : ''))
        },
        setImage(image) {
            this.detail.image_id = image.image_id
            this.detail.image_url = image.image_url
        },
		saveItem() {
			if (this.saving) { return }
			this.message = ''
            this.errorStatus = false

            if (!this.detail.content_type_id) {
				this.message = 'Select a Content Type.'
                this.errorStatus = 'invalid'
            }
            if (!this.detail.description) {
				this.message = 'Enter a Description.'
                this.errorStatus = 'invalid'
            }
            if (this.errorStatus) {
                return
            }

			this.saving = true
    		this.apiCall({ destination: 'save_item_content', data: this.detail }).then(obj => {
				this.saving = false
    			if (obj.status == 'success') {
					this.detail.content_id = obj.content_id
					this.message = 'Content saved.'
                    setTimeout(() => {
                        this.message = ''
                        if (this.detail.content_id != this.contentId) {
                            this.$router.push('/content/detail/' + this.detail.content_id)
                        }
                    }, 2000)
					this.saved = true
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
		},
        backToList() {
            this.$router.push('/content')
        }
    }
}
</script>
