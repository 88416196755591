<template>
    <div @dragenter.prevent="toggleActive" @dragleave.prevent="toggleActive" @dragover.prevent="doNothing" @drop.prevent="doDrop" :class="['dropzone', { 'active': active }]">
        <span v-if="!uploading">Drag {{ fileType == 'image' ? 'an image' : '' }} file here</span>
        <span v-if="!uploading">OR</span>
        <label v-if="!uploading" class="button button-dark" for="dropzoneFile">Select a File</label>
        <input type="file" id="dropzoneFile" class="dropzoneFile" :accept="fileType == 'image' ? '.jpg,.png,.gif' : ''" @change="setFile()" />
        <span v-if="uploading || uploadComplete">{{ uploadMessage }}</span>
        <font-awesome-icon v-if="uploading && !uploadComplete" icon="cog" class="spin uploading-icon" />
        <font-awesome-icon v-if="uploadComplete" :icon="uploadError ? 'exclamation' : 'check'" class="uploading-icon" />
    </div>
</template>

<script>
export default {
    name: 'Dropzone',
    props: {
        fileType: {
            type: String,
            default: ''
        },
        imageId: {
            type: Number
        }
    },
    data() {
        return {
            active: false,
            file: {},
            uploading: false,
            uploadComplete: false,
            uploadMessage: '',
            uploadCompleteMessage: '',
            uploadError: false,
            imageTypes: ['image/png','image/jpeg','image/gif'],
            fileResult: {
                image_id: '',
                image_url: ''
            }
        }
    },
    created() {
    },
    methods: {
        toggleActive(event) {
            this.active = !this.active
        },
        doNothing(event) {
            return
        },
        setFile() {
            this.file = document.getElementById('dropzoneFile').files[0]
            this.uploadFile()
        },
        doDrop(event) {
            this.active = false
            this.file = event.dataTransfer.files[0]
            if (this.fileType == 'image' && !this.imageTypes.includes(this.file.type)) {
                this.uploadComplete = true
                this.uploadMessage = 'File type ' + this.file.type + ' not allowed'
                this.uploadError = true
                setTimeout(() => {
                    this.uploadMessage = ''
                    this.uploadError = false
                    this.uploadComplete = false
                    this.uploading = false
                }, 2500)
                return
            }
            this.uploadFile()
        },
        uploadFile() {
            if (this.uploading) { return }
            this.uploading = true
            this.uploadMessage = 'Uploading ' + this.file.name + ' (' + this.fileSize(this.file.size) + ')'
            const formData = new FormData()
            formData.append('source', 'ADMIN')
            formData.append('image_id', this.imageId)
            formData.append('upload_file', this.file)
            let developmentEnvironment = (document.location.hostname.indexOf('localhost') > -1)
            let sandboxEnvironment = (document.location.hostname.indexOf('sandbox') > -1)
            let hostname = ''
            if (developmentEnvironment) {
                hostname = 'gapapi.test'
            } else if (sandboxEnvironment) {
                hostname = 'api.sandbox.gapmovement.com'
            } else {
                hostname = 'api.gapmovement.com'
            }
            let url = 'https://' + hostname + '/api.php?action=upload_image'
            let headers = {
                method: 'post',
                body: formData,
                credentials: 'include'
            }
            fetch(url, headers).then(function(response) {
                return response.json()
            }).then(obj => {
                this.uploadComplete = true
                if (obj.status == 'success') {
                    this.uploadMessage = obj.message || 'Upload complete!'
                    this.fileResult = {
                        image_id: obj.image_id,
                        image_url: obj.image_url
                    }
                    this.$emit('update', this.fileResult)
                } else {
                    this.uploadMessage = obj.message || 'Upload error!'
                    this.uploadError = true
                }
                setTimeout(() => {
                    this.uploadMessage = ''
                    this.uploadError = false
                    this.uploadComplete = false
                    this.uploading = false
                }, 2500)
            }).catch(error => {
                this.uploadMessage = 'Upload error!'
                this.uploadComplete = true
                this.uploadError = true
                setTimeout(() => {
                    this.uploadMessage = ''
                    this.uploadError = false
                    this.uploadComplete = false
                    this.uploading = false
                }, 2500)
            })
        },
        fileSize(size) {
            if (size > 1048576) {
                return Number.parseFloat(size / 1048576).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return Number.parseFloat(size / 1024).toFixed(2) + ' KB'
            } else {
                return size + ' bytes'
            }
        }
    }
}
</script>
<style scoped lang="scss">
.dropzone {
    position: relative;
    height: 9em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1em;
    border: 2px dashed #ccc;
    background-color: #f4f4f4;
    color: #666;
    transition: 0.3s ease all;
    label {
        font-size: 0.7em;
        padding: 0.5em 1em;
        transition: 0.3s ease all;
    }
    input {
        display: none;
    }
    &.active {
        background-color: #e4e4e4;
    }
    .uploading-icon {
        font-size: 3em;
    }
}
</style>
